// libraries
import { useStaticQuery, graphql } from 'gatsby';

export const useGetPosts = () => {
    const data = useStaticQuery(graphql`
        {
            allWpPost {
                nodes {
                    title
                    databaseId
                    excerpt
                    content
                    slug
                    uri
                    date(formatString: "DD MMM, YYYY", locale: "ES")
                    featuredImage {
                        node {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    categories {
                        nodes {
                            id
                            name
                            slug
                        }
                    }
                    tags {
                        nodes {
                            id
                            name
                        }
                    }
                }
            }
        }
    `);

    return data.allWpPost.nodes;
};
