// libraries
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import 'normalize.css';
import styled from 'styled-components';
// components
import { SEO } from 'components/SEO';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Container, GlobalGridVars, BREAKPOINT } from 'components/UI/Grid';
import { Layout } from 'components/Layout';
import { Card } from 'components/Blog/Card';
import { Button } from 'components/UI/Button';
// constants
import { COLORS } from 'constants/settings';
// helpers
import { sortPost } from 'helpers/blog';
// hooks
import { useGetPosts } from 'hooks/use-get-posts';
import { useGetPostCategories } from 'hooks/use-get-post-categories';

const seoSetting = {
    canonical: '',
    metaDesc: 'Aquí encontraras todas las noticias del sector financiero',
    metaRobotsNofollow: 'follow',
    metaRobotsNoindex: 'index',
    title: 'Mis finanzas – Blog sobre dinero y más',
    breadcrumbs: [
        {
            text: 'Inicio',
            url: '/',
        },
        {
            text: 'Blog',
            url: '/blog/',
        },
    ],
};

const Blog = (data) => {
    const { pageContext } = data;
    const allPosts = useGetPosts();
    const allCategories = useGetPostCategories();
    const filteredCategories = allCategories
        .filter((i) => i.slug !== 'blog')
        .filter((category) => category.posts.nodes.length > 0);
    const postsPerView = 10;
    const countUploadedPosts = 9;
    const [tagId, setTagId] = useState(-1);
    const sortedPost = sortPost(tagId, allPosts);
    const [slicedPostCount, setSlicePostCount] = useState(postsPerView);
    const [showButtonMore, setShowButtonMore] = useState(sortedPost.length > postsPerView + 1);
    const avgSpeedReading = 1500;
    const [allCategoriesArrs, setAllCategoriesArrs] = useState([]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const tabValue = params.get('tab');
        const tagName = filteredCategories.find((i) => i.slug === tabValue);
        if (tagName) {
            setTagId(tagName.id);
        }
    }, []);

    useEffect(() => {
        setSlicePostCount(postsPerView);
        setShowButtonMore(sortedPost.length > postsPerView);
        filteredCategories.unshift({
            name: 'Todo',
            id: -1,
        });
        setAllCategoriesArrs(filteredCategories);
    }, [sortedPost.length]);

    const tabClickHandler = (tagName, slug) => {
        setTagId(tagName);
        setSlicePostCount(postsPerView);
        setShowButtonMore(true);
        navigate(!slug ? '/blog/' : `/blog?tab=${slug}`);
    };

    const moreButtonHandler = (e) => {
        e.target.blur();

        if (sortedPost.length <= slicedPostCount + postsPerView) {
            setShowButtonMore(false);
        }

        setSlicePostCount(slicedPostCount + countUploadedPosts);
    };

    if (allPosts.length === 0) {
        return (
            <Layout>
                <Container>
                    <p>Aún no hay noticias.</p>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout pageType={pageContext.pageType}>
            <GlobalStyles />
            <GlobalGridVars />
            <StyledBlog>
                <BlogContainer>
                    <StyledTitle>Mis finanzas – Blog sobre dinero y más</StyledTitle>
                    <StyledTabList>
                        {allCategoriesArrs.map((item, index) => (
                            <StyledTagButton
                                key={index}
                                title={item.description}
                                dangerouslySetInnerHTML={{
                                    __html: item.name,
                                }}
                                className={tagId === item.id ? 'active' : ''}
                                onClick={() => {
                                    tabClickHandler(item.id, item.slug);
                                }}
                            />
                        ))}
                    </StyledTabList>
                    <StyledPostsFeed>
                        {sortedPost &&
                            sortedPost
                                .slice(0, slicedPostCount)
                                .map(
                                    (
                                        { title, databaseId, content, excerpt, date, uri, featuredImage, categories },
                                        index
                                    ) => (
                                        <Card
                                            variant={index === 0 ? 'main' : ''}
                                            key={databaseId}
                                            tags={categories}
                                            title={title}
                                            content={excerpt}
                                            date={date}
                                            url={uri}
                                            featuredImage={featuredImage && featuredImage.node.localFile.publicURL}
                                            timeReading={Math.round(content.length / avgSpeedReading)}
                                        />
                                    )
                                )}
                    </StyledPostsFeed>
                    {showButtonMore && (
                        <StyledReadMore aria-label="Load more" onClick={(e) => moreButtonHandler(e)}>
                            Ver más
                        </StyledReadMore>
                    )}
                </BlogContainer>
            </StyledBlog>
        </Layout>
    );
};

const StyledBlog = styled.section`
    padding-top: 72px;

    ${BREAKPOINT.md} {
        padding-top: 113px;
        padding-bottom: 40px;
    }
`;

const BlogContainer = styled(Container)`
    ${BREAKPOINT.xl} {
        max-width: 1132px;
    }
`;

const StyledTitle = styled.h1`
    margin-bottom: 0;
`;

const StyledPostsFeed = styled.div`
    display: grid;
    grid-row-gap: 24px;

    ${BREAKPOINT.sm} {
        margin: auto;
        padding: 0;
    }

    ${BREAKPOINT.md} {
        grid-gap: 40px 24px;
    }

    ${BREAKPOINT.xl} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const StyledReadMore = styled(Button)`
    display: block;
    width: 100%;
    margin: 40px auto 0;
    line-height: 1.3;

    ${BREAKPOINT.md} {
        width: 294px;
    }
`;

export default Blog;

export const Head = () => {
    return <SEO data={seoSetting} />;
};

const StyledTabList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin: 24px 0;

    ${BREAKPOINT.lg} {
        gap: 24px;
        justify-content: left;
        margin: 32px 0;
    }
`;

const StyledTagButton = styled.button`
    position: relative;
    padding: 4px 8px;
    color: ${COLORS.blogText};
    font-size: 14px;
    background: ${COLORS.secondary};
    border-radius: 100px;

    ${BREAKPOINT.md} {
        font-size: 16px;
    }

    &.active {
        background: ${COLORS.success};
    }
`;
