// libraries
import { useStaticQuery, graphql } from 'gatsby';

export const useGetPostCategories = () => {
    const data = useStaticQuery(graphql`
        {
            allWpCategory {
                nodes {
                    id
                    name
                    slug
                    description
                    posts {
                        nodes {
                            databaseId
                        }
                    }
                }
            }
        }
    `);

    return data.allWpCategory.nodes;
};
